import { nftsAbstracts } from "contains/fakeData";


import HIW1 from "images/hWork/reunion.webp";
import HIW2 from "images/hWork/eleccion.webp";
import HIW3 from "images/hWork/discusion.webp";
import HIW4 from "images/hWork/subir.webp";
import customWeb from "images/services/customizacionweb.webp";
import customHosting from "images/services/hosting.webp";
import customSsl from "images/services/ssl.webp";
import customMantencion from "images/services/mantencionweb.webp";
import customresponsive from "images/services/responsive.webp";
import customseo from "images/services/seo.webp";

import photoPerfil from "images/about/me.webp";
import photoPerfil2 from "images/about/nicolas.jpg";

export interface PricingItem {
  isPopular: boolean;
  name: string;
  pricing: string;
  pricing2: string;
  desc: string;
  per: string;
  features: string[];
  offer?: {
    discount: string;
    pricing: string;
  };
}

export const CustomersData = [
  {
    id:1,
    name:'Santiago PaperChase',
    href:'https://santiagopaperchase.netlify.app/',
    image:'https://res.cloudinary.com/drju7lpxl/image/upload/v1712854141/BecauseYes/Customers/zan2v61ocqwhbcrmx6qs.png',
    showHome: true
  },
  {
    id:2,
    name:'CYVM',
    href:'https://cyvm.cl/',
    image:'https://res.cloudinary.com/drju7lpxl/image/upload/v1712854140/BecauseYes/Customers/sfkc34rdp0hn7pnfk8um.png',
    showHome: true
  },
  {
    id:3,
    name:'Carpas MRozas',
    href:'https://carpaseventosmrozas.com/',
    image:'https://res.cloudinary.com/drju7lpxl/image/upload/v1712854142/BecauseYes/Customers/nhecqwuhtb2luxxfe4bg.png',
    showHome: true
  },
  {
    id:4,
    name:'Veterinaria Móvil Pet',
    href:'https://movilpet.cl/',
    image:'https://firebasestorage.googleapis.com/v0/b/vmmpet-db.appspot.com/o/vmmpet%2Fmovilpet-screen.png?alt=media&token=6044e078-b707-4feb-98cd-b4863e5361fa',
    showHome: true
  },
  {
    id:5,
    name:'Unne',
    href:'https://unne.cl/',
    image:'https://firebasestorage.googleapis.com/v0/b/vmmpet-db.appspot.com/o/customers%2FunneScreen.png?alt=media&token=4013e082-d49f-4401-8f0d-9bb01d762a3f',
    showHome: true
  },
  {
    id:6,
    name:'Constructora Vasben',
    href:'https://constructoravasben.cl/',
    image:'https://firebasestorage.googleapis.com/v0/b/constructoravasben-bd-6942f.firebasestorage.app/o/FondoWeb%2FfondoVasben.png?alt=media&token=76670820-114b-4970-a17f-45f7a1c66679',
    showHome: true
  },
  {
    id:7,
    name:'Calexpro',
    href:'https://calexpro.cl/',
    image:'https://res.cloudinary.com/drju7lpxl/image/upload/v1739387879/calexpro/Captura_de_pantalla_2025-02-04_174841_e60ooy.png',
    showHome: true
  },
]

export const servicesData = [
  {
    featured:true,
    img:customWeb,
    title:"Customización web",
    descrip:"proceso de ajustar y personalizar el diseño, la apariencia y la funcionalidad de un sitio web de acuerdo con las necesidades y preferencias específicas."
  }, 
  {
    featured:false,
    img:customMantencion,
    title:"Mantención.",
    descrip:"proceso continuo de actualización, corrección de errores, optimización y gestión de recursos para garantizar el rendimiento y la relevancia de una página web."
  },    
  {
    featured:false,
    img:customHosting,
    title:"Hosting",
    descrip:"Alojamiento para su página web, permitiendo que tu sitio esté disponible para visitantes en línea las 24 horas del día."
  },    
  {
    featured:false,
    img:customSsl,
    title:"Cerificado SSL",
    descrip:"Proporciona una conexión segura, garantizando que los datos transmitidos estén protegidos contra posibles amenazas y garantizando la confidencialidad de la información del usuario."
  },    
  {
    featured:false,
    img:customresponsive,
    title:"Diseño responsive",
    descrip:"Capacidad de un sitio web para adaptarse y ofrecer una experiencia óptima en diversos dispositivos y tamaños de pantalla."
  },    
  {
    featured:false,
    img:customseo,
    title:"Posicionamiento web SEO",
    descrip:"Conjunto de estrategias y técnicas para mejorar la visibilidad de un sitio web en los resultados de búsqueda."
  }
];

export const HowItWork_Data = [
  {
    id: 1,
    img: HIW1,
    imgDark: HIW1,
    title: "Contacta",
    desc: "Comunicate con nosotros para obtener la información que necesitas.",
  },
  {
    id: 2,
    img: HIW2,
    imgDark: HIW2,
    title: "Customiza",
    desc: "Podras customizar colores, diseño e imagenes que desees.",
  },
  {
    id: 3,
    img: HIW3,
    imgDark: HIW3,
    title: "Visto bueno",
    desc: "Luego de un tiempo te enviaremos tu plantilla web hecha, para una última revision.",
  },
  {
    id: 4,
    img: HIW4,
    imgDark: HIW4,
    title: "Genera vistas",
    desc: "Finalmente si el diseño cumple con tus expectativas, podremos activarla en la web, en cuestion de horas.",
  },
];

export const pricingsServices: PricingItem[] = [
  {
    isPopular: true,
    name: "Standard",
    pricing: "$129.990",
    pricing2: "$9.990",
    per: "/primer pago",
    features: [
      "Landing",
      "Diseño Adaptativo",
      "Dominio",
      "Hosting",
      "Certificado SSL",
      "Email Personalizados"
    ],
    desc: `Al seleccionar, estás aceptando los términos y condiciones.`,
    offer:{
      /* discount:'20%',
      pricing:'$71.990' */
      discount:'',
      pricing:''
    }
  },
  {
    isPopular: false,
    name: "Premium",
    pricing: "$239.990",
    pricing2: "$12.990",
    per: "/primer pago",
    features: [
      "Landing o Páginas",
      "Diseño Adaptativo",
      "Dominio",
      "Hosting",
      "Certificado SSL",
      "Posicionamiento Web (SEO)",
      "Email Personalizados"
    ],
    desc: `Al seleccionar, estás aceptando los términos y condiciones.`,

    offer:{
      discount:'18%',
      pricing:'$196.790'
    }
  },
];


export const AboutData = {
  main: {
    title:'👋 Sobre Nosotros',
    description: 'Bienvenidos a nuestro equipo de programadores altamente calificados y dedicados a llevar tus ideas a la vida digital. Somos un conjunto apasionado de profesionales que combinan conocimientos técnicos avanzados con una creatividad sin límites.'
  },
  // Statistic:{
  //   title:'🚀 Datos Impactantes',
  //   description:'Somos imparciales e independientes, y cada día creamos páginas y contenido distintivo.',
  //   FOUNDER_DEMO: [
  //     {
  //       id: "1",
  //       heading: "10 millones",
  //       subHeading:
  //         "Diseños webs han sido publicados en todo el mundo",
  //     },
  //     {
  //       id: "2",
  //       heading: "100,000",
  //       subHeading: "Cuentas de usuarios registrados",
  //     },
  //     {
  //       id: "3",
  //       heading: "220+",
  //       subHeading:
  //         "Clientes cuentan con nuestra presencia",
  //     },
  //   ]
  // },
  founders:{
    title:'Fundadores',
    description:'Cada miembro de nuestro equipo es informático con una sólida formación académica y con 2 o más años experiencia en el desarrollo web.',
    profile:[
      {
        id: "1",
        name: `Fabian Angelo Astete Salas`,
        job: "Co-fundador, director ejecutivo y desarrollador web",
        avatar:photoPerfil,
        hrefInst:'https://www.instagram.com/sawi__wi/',
        hrefGit:'https://github.com/sawiwi',
        hrefLink:'https://www.linkedin.com/in/fabian-salas-astete-820a45190/',
      },
      {
        id: "2",
        name: `Nicolás Cabrera Millacan`,
        job: "Co-fundador, directo ejecutivo y ingeniero informatico",
        avatar:photoPerfil2,
        hrefInst:'',
        hrefGit:'https://github.com/jaackx1',
        hrefLink:'https://www.linkedin.com/in/nicolas-cabrera-m',
      },

    ]
  }
}